body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "fira";
  src: local("fira"), url(./assets/fonts/FiraSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "fira-bold";
  src: local("fira-bold"), url(./assets/fonts/FiraSans-Bold.ttf) format("truetype");
}

body .slick-track {
  line-height: 0;
}
